import { isReportableResourceEntry } from './isReportableResourceEntry';
import { getResourcePerformanceEvent } from './resourcePerformanceEvent';
import { GetEventsForPerformanceEntries } from './types';

const getEventsForResourceEntries: GetEventsForPerformanceEntries = (entries) =>
  entries
    .filter((entry) => isReportableResourceEntry(entry as PerformanceResourceTiming))
    .map((en) => getResourcePerformanceEvent(en as PerformanceResourceTiming));

export default getEventsForResourceEntries;
