import config from '../config';

const validSuffixes = [
  'rs-mcas.ms',
  'admin-rs-mcas.ms',
  'mcas.ms',
  'admin-mcas.ms',
  'rs-mcas-df.ms',
  'admin-rs-mcas-df.ms',
  'mcas-df.ms',
  'admin-mcas-df.ms',
];
const { clientId } = config.msal;

const getDeepLinkFromMsal = (): string | null => {
  if (window.sessionStorage == null) {
    return null;
  }

  const key = `msal.${clientId}.request.origin`;

  return window.sessionStorage.getItem(key);
};

export const redirectToMcasUrl = () => {
  const currentUrl = new URL(window.location.href);
  const suffix = currentUrl.searchParams.get('suffix');

  if (!suffix) {
    throw new Error('Missing suffix param');
  }

  if (!validSuffixes.includes(suffix)) {
    throw new Error('Invalid MCAS suffix');
  }

  const deeplink = getDeepLinkFromMsal();

  const destination = deeplink ? deeplink : currentUrl.origin;

  const destinationURL = new URL(destination);
  destinationURL.hostname += '.' + suffix;

  window.location.href = destinationURL.href;
};
