import { AnalyticsEvent, ErrorEvent, LogEvent, PerformanceEvent, TelemetryProperties } from '@ms/yammer-telemetry';
import { getClientContext, getTelemetryHostingConfig } from '@ms/yammer-telemetry-store';

import { AnalyticsEventInput, LogEventInput } from './eventInputTypes';
import { getNetworkInformation } from './getNetworkInformation';
import translateLegacyEventsProperties from './translateLegacyEventsProperties';

const getErrorEventSpecificProperties = ({ message, stack }: ErrorEvent) => ({
  ...getTelemetryHostingConfig(),
  message,
  stack,
});

const getAnalyticsEventSpecificProperties = () => ({
  scriptRevision: getTelemetryHostingConfig().scriptRevision,
});

const getPerformanceEventSpecificProperties = ({ startTime, duration }: PerformanceEvent) => {
  const { downlink, downlinkMax, effectiveType, rtt, saveData, type } = getNetworkInformation();

  const endTime = typeof startTime === 'number' && typeof duration === 'number' ? duration + startTime : undefined;

  return {
    ...getTelemetryHostingConfig(),
    connectionDownlink: downlink,
    connectionDownlinkMax: downlinkMax,
    connectionEffectiveType: effectiveType,
    connectionRtt: rtt,
    connectionSaveData: saveData,
    connectionType: type,
    duration,
    startTime,
    endTime,
  };
};

const getLogEventSpecificProperties = (event: LogEvent) => {
  switch (event.type) {
    case 'Error':
      return getErrorEventSpecificProperties(event);
    case 'Info':
      return getTelemetryHostingConfig();
    default:
      return getPerformanceEventSpecificProperties(event);
  }
};

const getTelemetryEventProperties = (): TelemetryProperties => {
  const { deviceType, hostClientType, osName, clientFeedLayout, hostAppName, isPWA, hostSessionId, hostRingId } =
    getClientContext();

  return {
    ...(deviceType && { deviceType: deviceType.toLowerCase() }),
    ...(hostClientType && { hostClientType: hostClientType.toLowerCase() }),
    ...(hostAppName && { hostAppName: hostAppName.toLowerCase() }),
    ...(osName && { OsName: osName.toLowerCase() }),
    ...(clientFeedLayout && { clientFeedLayout: clientFeedLayout.toLowerCase() }),
    ...(isPWA && { isPWA }),
    ...(hostSessionId && { hostSessionId }),
    ...(hostRingId && { ringId: hostRingId }),
  };
};

type GetAnalyticsEventInput = (event: AnalyticsEvent) => AnalyticsEventInput;

export const getAnalyticsEventInput: GetAnalyticsEventInput = (analyticsEvent) => ({
  name: analyticsEvent.name,
  properties: {
    ...getAnalyticsEventSpecificProperties(),
    ...translateLegacyEventsProperties(analyticsEvent),
    ...getTelemetryEventProperties(),
    client_event_time: analyticsEvent.occurredAt,
  },
});

type GetLogEventInput = (event: LogEvent) => LogEventInput;

export const getLogEventInput: GetLogEventInput = (event) => ({
  EventName: event.name,
  ClientTimeStamp: Number(event.occurredAt),
  LogType: event.type.toLowerCase(),
  Parameters: {
    ...getLogEventSpecificProperties(event),
    ...event.properties,
    ...getTelemetryEventProperties(),
    client_event_time: event.occurredAt,
  },
});
