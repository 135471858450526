import { configurePerformanceObserver } from '@ms/yammer-telemetry';
import {
  clearAllTelemetryEvents,
  getSkipCleanupFlag,
  removeClientContext,
  removeFirstMeaningfulPaintContext,
  removeScenarioPerformanceCollection,
  removeUserContext,
  setClientContext,
  setSkipCleanupFlag,
  setTelemetryConfig,
} from '@ms/yammer-telemetry-store';
import { ClientContext, HostingConfig } from '@ms/yammer-telemetry-support';

import { Config } from '../../state/types';
import { reportError } from '../api';
import { enqueueAndThrottleReportLogEvents } from '../report/reportLog';

import { addUnhandledErrorEventListener, addUnhandledRejectionEventListener } from './addEventListeners';

const checkIfBrowserPerformanceApiAvailable = () => {
  if (!(typeof window !== undefined && window.performance && !!window.performance.mark)) {
    reportError({
      error: new Error('window.performance object is not available'),
      eventProperties: { errorCode: 'PerformanceApiUnavailable' },
    });
  }
};

interface ConfigureTelemetryOptions {
  /**
   * Client configuration
   */
  readonly config: Config;

  /**
   * Hosting service configuration
   */
  readonly hostingConfig: HostingConfig;

  /**
   * Whether or not to skip clearing all queued events and user context from session storage the next time
   * telemetry is configured. You may want to set this to true if you are calling configure from a bootstrap
   * sequence that reloads the page for the same user when successful. Set to false by default.
   */
  readonly skipNextCleanup?: boolean;

  /**
   * Whether or not to register an event listener for unhandled errors or rejections.
   */
  readonly registerUnhandledEventsListener?: boolean;
  readonly isMsal?: boolean;
  readonly clientContext?: ClientContext;

  /**
   * If protobuf (analytics v2) events should be logged to the console before they're sent to the api/v3/events endpoint.
   */
  readonly shouldLogProtobufEventsToConsole?: boolean;

  /**
   * If true, the PerformanceObserver will not be used to collect performance data.
   */
  readonly skipConfigPerformanceObserver?: boolean;

  /**
   * If true, reporting of long animation frame timing will be enabled in the performance observer.
   */
  readonly enableLongAnimationFrameTimingReporting?: boolean;
}

type ConfigureTelemetry = (configureOptions: ConfigureTelemetryOptions) => void;

export const configureTelemetry: ConfigureTelemetry = ({
  config,
  hostingConfig,
  clientContext,
  skipNextCleanup = false,
  registerUnhandledEventsListener = false,
  isMsal = false,
  shouldLogProtobufEventsToConsole = false,
  skipConfigPerformanceObserver = false,
  enableLongAnimationFrameTimingReporting = false,
}) => {
  if (!getSkipCleanupFlag()) {
    clearAllTelemetryEvents();
    removeUserContext();
    removeClientContext();
    removeFirstMeaningfulPaintContext();
  }

  // Make sure the scenario performance collection is clean on every boot
  // since the performance marks are always gone.
  removeScenarioPerformanceCollection();

  const currentTimeTicks = Date.now();
  const randomPerTabSalt = Math.floor(Math.random() * 10000000);
  const clientConfig = {
    ...config.telemetry,
    appInstanceId: `${currentTimeTicks}.${randomPerTabSalt}`,
    loadedAt: currentTimeTicks,
    resource: isMsal ? config.msal.resources.yammer : config.adal.resources.yammer,
    graphqlHost: config.hosts.graphql,
    telemetryHost: config.hosts.telemetry,
    shouldLogProtobufEventsToConsole,
  };

  setTelemetryConfig(clientConfig, hostingConfig);
  setSkipCleanupFlag(skipNextCleanup);

  if (registerUnhandledEventsListener) {
    addUnhandledErrorEventListener();
    addUnhandledRejectionEventListener();
  }

  checkIfBrowserPerformanceApiAvailable();

  if (!skipConfigPerformanceObserver) {
    configurePerformanceObserver({
      enableLongAnimationFrameTimingReporting,
      reportCallback: (events) => enqueueAndThrottleReportLogEvents('Performance', events),
    });
  }

  if (clientContext) {
    setClientContext(clientContext);
  }
};
