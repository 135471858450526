import { PerformanceEventName, PerformanceEventProperties, PerformanceMeasure } from '@ms/yammer-telemetry-support';

import { PerformanceEvent } from '../events';

export type { PerformanceMeasure, PerformanceEventName };

export enum PerformanceEntryType {
  Resource = 'resource',
}

export enum ResourceEntryType {
  Flighting,
  GraphQL,
  JavaScript,
  Unknown,
}

export type ScenarioEventName = Extract<PerformanceEventName, 'first_meaningful_paint'>;

export type MeasureNamedPerformanceEvent = Extract<
  PerformanceEventName,
  'ui_component_measure' | 'managed_api_action' | 'api_fetch'
>;

export type NamedPerformanceEvent = Exclude<PerformanceEventName, MeasureNamedPerformanceEvent>;

export interface StartPerformanceMeasure {
  (eventName: NamedPerformanceEvent): PerformanceMeasure;
  (eventName: MeasureNamedPerformanceEvent, measureName: string): PerformanceMeasure;
}

export type EndAndReportPerformanceMeasure = (options: ReportPerformanceOptions) => void;

/**
 * The `PerformanceMeasure` properties returned by `startPerformanceMeasure` and an
 * optional set of custom properties to log for the event. These properties must be defined in
 * an interface in the telemetry types file and added as part of the PerformanceEventProperties type
 * (see the README about the process for adding new custom properties). Each value passed here will
 * be logged into a property-named column in the 'yamjs' table. There may be extra work involved to
 * add a property column if it does not already exist in the 'yamjs' table (see README).
 */
export type ReportPerformanceOptions = PerformanceMeasure & {
  readonly fromNavigationStart?: boolean;
  readonly durationOffset?: number;
  readonly eventProperties?: PerformanceEventProperties;
};

export type GetEventsForPerformanceEntries = (entries: PerformanceEntry[]) => PerformanceEvent[];
