import { basenameForWebApp } from './basename';
import { trimBasenameFromUrlPath } from './trimBasenameFromUrlPath';

/**
 * The root paths for the web app.
 * if you're adding a new root path that is not pointing to resources(e.g. thread/group/article),
 * please add it to the ignorePaths array in resourcePath.ts
 */
export enum UrlRootPath {
  achievements = 'achievements',
  articles = 'articles',
  authredirect = 'authredirect',
  configure = 'configure',
  curatedThreadList = 'curated-thread-list',
  discoverGroups = 'discover-groups',
  events = 'events',
  feed = 'feed',
  groups = 'groups',
  campaigns = 'campaigns',
  logout = 'logout',
  inbox = 'inbox',
  campaignsDashboard = 'campaigns-dashboard',
  campaignsHub = 'campaigns-hub',
  questions = 'questions',
  search = 'search',
  storylines = 'storylines',
  teamsMeeting = 'teams-meeting',
  threads = 'threads',
  topics = 'topics',
  topicsNetworkQuestion = 'topics-network-question',
  networkThemeFeed = 'network-themes',
  usagePolicy = 'usage-policy',
  users = 'users',
  userSettings = 'user-settings',
  org = 'org',
  network = 'network',
  admin = 'admin',
  leaderDashboard = 'leader-dashboard',
  leadershipCorner = 'leadership-corner',
  leaders = 'leaders',
  askMeAnything = 'ama',
  insights = 'insights',
  mediaPost = 'media-post',
  communicationsDashboard = 'communications-dashboard',
}

export const trimLeadingSlash = (urlPath: string) => urlPath.replace(/^\//, '');

const getRootPath = (urlPath: string) => {
  const urlPathWithoutBasename = trimBasenameFromUrlPath(urlPath);
  const trimmedUrlPath = trimLeadingSlash(urlPathWithoutBasename);
  const urlPathParts = trimmedUrlPath.split('/');
  if ([UrlRootPath.org, UrlRootPath.network].includes(urlPathParts[0] as UrlRootPath)) {
    return urlPathParts.length > 2 ? urlPathParts[2] : '';
  }

  return urlPathParts[0];
};

export type GetUrlRootPath = (urlPath: string) => string;
export const getUrlRootPath: GetUrlRootPath = (urlPath) => {
  const rootPath = getRootPath(urlPath);

  return rootPath === '' || Object.values(UrlRootPath).includes(rootPath as UrlRootPath) ? rootPath : 'other';
};

export type GetTenantFromPath = (urlPath: string) => string | null;
export const getTenantFromPath: GetTenantFromPath = (urlPath) => {
  if (!(urlPath.indexOf(basenameForWebApp) >= 0)) {
    return null;
  }

  const rootPath = getRootPath(urlPath);
  const isRootPathNetworkPermalink =
    !Object.values(UrlRootPath).includes(rootPath as UrlRootPath) && rootPath && rootPath !== '';

  return isRootPathNetworkPermalink ? rootPath : null;
};
