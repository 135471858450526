import { getTokenClaims } from '@ms/yammer-web-authenticators';

import { get, remove, set } from '../cookies';

import { ShouldWriteCookie, WriteCookie, WriteHostingCookieOptions, writeHostingCookie } from './writeHostingCookie';

const OpenIdConnectYammerTokenCookieName = 'OpenIdConnect.id_token.yammer';

const ThreeDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;

const shouldWriteSessionCookie: ShouldWriteCookie = (newYammerToken: string) => {
  const yammerToken = get(OpenIdConnectYammerTokenCookieName);
  if (!yammerToken) {
    return true;
  }

  const { oid: newOid, tid: newTid } = getTokenClaims(newYammerToken);
  const { oid: existingOid, tid: existingTid } = getTokenClaims(yammerToken);

  return existingTid !== newTid || existingOid !== newOid;
};

const shouldWritePersistentCookie: ShouldWriteCookie = () => true;

const writeCookie: WriteCookie = (yammerToken, defaultCookieOptions) => {
  clearAuthCookie();
  set(OpenIdConnectYammerTokenCookieName, yammerToken, defaultCookieOptions);
};

export interface WriteAuthCookieOptions {
  readonly yammerToken: string;
  readonly shouldSetSameSite?: boolean;
  readonly shouldSetExpiry?: boolean;
  readonly partitioned?: boolean;
}

type WriteAuthCookie = (options: WriteAuthCookieOptions) => void;
export const writeAuthCookie: WriteAuthCookie = ({
  yammerToken,
  shouldSetSameSite = false,
  shouldSetExpiry = false,
  partitioned = false,
}) => {
  const shouldWriteCookie = shouldSetExpiry ? shouldWritePersistentCookie : shouldWriteSessionCookie;
  const expires = shouldSetExpiry ? new Date(Date.now() + ThreeDaysInMilliseconds) : undefined;

  const options: WriteHostingCookieOptions = {
    cookieName: OpenIdConnectYammerTokenCookieName,
    cookieValue: yammerToken,
    shouldWriteCookie,
    writeCookie,
    shouldSetSameSite,
    partitioned,
    expires,
  };

  writeHostingCookie(options);
};

type CheckAuthCookieAvailability = () => boolean;
export const checkAuthCookieAvailability: CheckAuthCookieAvailability = () => !!get(OpenIdConnectYammerTokenCookieName);

type ClearAuthCookie = () => void;
export const clearAuthCookie: ClearAuthCookie = () => {
  remove(OpenIdConnectYammerTokenCookieName, { path: '/' });
};
