import { PerformanceEvent } from '../events';

import getEventsForResourceEntries from './getEventsForResourceEntries';
import { GetEventsForPerformanceEntries, PerformanceEntryType } from './types';

const mappers: Record<PerformanceEntryType, GetEventsForPerformanceEntries> = {
  resource: getEventsForResourceEntries,
};

const isPerformanceObserverAvailable = () => 'performance' in window && 'PerformanceObserver' in window;

type ReportCallback = (events: PerformanceEvent[]) => void;

const reportEventsForEntries = (
  entryTypes: PerformanceEntryType[],
  list: PerformanceObserverEntryList,
  reportCallback: ReportCallback
) => {
  entryTypes.forEach((entryType) => {
    const entries = list.getEntriesByType(entryType);
    const getEventsForEntries = mappers[entryType];
    const events = getEventsForEntries(entries);
    if (events.length > 0) {
      reportCallback(events);
    }
  });
};

interface PerformanceObserverOptions {
  readonly enableLongAnimationFrameTimingReporting: boolean;
  readonly reportCallback: ReportCallback;
}

type ConfigurePerformanceObserver = (options: PerformanceObserverOptions) => void;
export const configurePerformanceObserver: ConfigurePerformanceObserver = ({ reportCallback }) => {
  if (!isPerformanceObserverAvailable()) {
    return;
  }

  const entryTypes: PerformanceEntryType[] = [PerformanceEntryType.Resource];

  // One-time report for entries created before configuring PerformanceObserver
  reportEventsForEntries(entryTypes, window.performance, reportCallback);

  const perfObserver = new PerformanceObserver((list: PerformanceObserverEntryList, _obj: PerformanceObserver) =>
    reportEventsForEntries(entryTypes, list, reportCallback)
  );
  perfObserver.observe({ entryTypes });
};
