import { CurrentNetworkContext, NetworkType } from '@ms/yammer-data/dist/state/types';

import { trimBasenameFromUrlPath } from './trimBasenameFromUrlPath';
import { getTenantFromPath, trimLeadingSlash } from './urlRootPath';

export type GetNetworkContext = (urlPath: string) => CurrentNetworkContext;
export const getNetworkContext: GetNetworkContext = (urlPath) => {
  const tenant = getTenantFromPath(urlPath);
  if (tenant != null) {
    return {
      networkType: NetworkType.Canonical,
      permalink: tenant,
      networkPath: 'org/' + tenant,
    };
  }

  const urlPathWithoutBasename = trimBasenameFromUrlPath(urlPath);
  const trimmedUrlPath = trimLeadingSlash(urlPathWithoutBasename);
  const urlPathParts = trimmedUrlPath.split('/');

  if (urlPathParts.length < 2) {
    return null;
  }

  if (urlPathParts[0] === 'network') {
    return {
      networkType: NetworkType.External,
      permalink: urlPathParts[1],
      networkPath: 'network/' + urlPathParts[1],
    };
  }

  if (urlPathParts[0] === 'org') {
    return {
      networkType: NetworkType.Canonical,
      permalink: urlPathParts[1],
      networkPath: 'org/' + urlPathParts[1],
    };
  }

  return null;
};
