import { RemoveCookieOptions, remove, set } from '../cookies';

import { WriteCookie, WriteHostingCookieOptions, writeHostingCookie } from './writeHostingCookie';

const ThirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;

const RingInfoCookieName = 'ring_info';
const RingInfoMetatagName = 'ringInfo';

const writeCookie: WriteCookie = (value, cookieOptions) => {
  set(RingInfoCookieName, value, cookieOptions);
};

type ClearAndWriteCookie = (path: string) => WriteCookie;
const clearAndWriteCookie: ClearAndWriteCookie = (path) => (value, cookieOptions) => {
  clearRingInfoCookie(path);
  writeCookie(value, cookieOptions);
};

const getRingInfo = () => {
  const { head } = document;
  const metaTag: HTMLMetaElement | null = head.querySelector(`[name='${RingInfoMetatagName}']`);

  return metaTag?.content ?? '';
};

type WriteRingInfoCookie = (shouldSetSameSite: boolean, path: string, partitioned?: boolean) => void;
export const writeRingInfoCookie: WriteRingInfoCookie = (shouldSetSameSite, path, partitioned = false) => {
  const ringInfo = getRingInfo();

  const options: WriteHostingCookieOptions = {
    cookieName: RingInfoCookieName,
    cookieValue: ringInfo,
    shouldWriteCookie: () => !!ringInfo,
    writeCookie: clearAndWriteCookie(path),
    shouldSetSameSite,
    expires: new Date(Date.now() + ThirtyDaysInMilliseconds),
    partitioned,
    path,
  };

  writeHostingCookie(options);
};

type ClearRingInfoCookie = (path: string) => void;
export const clearRingInfoCookie: ClearRingInfoCookie = (path) => {
  const options: RemoveCookieOptions | undefined = { path };

  remove(RingInfoCookieName, options);
};
