/* eslint-disable @ms/forbid-file-names */
import { getBasenameForUrlPath } from './getBasenameForUrlPath';

const trimBasename = (urlPath: string, basename: string) => urlPath.substring(basename.length);

export type TrimBasenameFromUrlPath = (urlPath: string) => string;
export const trimBasenameFromUrlPath: TrimBasenameFromUrlPath = (urlPath) => {
  const basename = getBasenameForUrlPath(urlPath);

  return basename ? trimBasename(urlPath, basename) : urlPath;
};
